import React from "react";
import { useEffect, useState } from "react";
import Header from "../../components/header/header.component";
import BigCard from "../../components/big-card/big-card.component";
import Section from "../../components/section/section.component";
import axios from "axios";
import { Box, Grid } from "@mui/material";
import Filters from "../../components/filters/filters.component";
import { getUserInfo } from "../../services/userInfo.service";
import DrawerMenu from "../../components/drawer/drawer.component";
import RunningExperimentsCard from "../../components/experiments/running-experiments-card/running-experiments-card.component";
import ExperimentsSection from "../../components/experiments/experiments-section/experiments-section.component";
import PendingExperimentsCard from "../../components/experiments/pending-experiments-card/pending-experiments-card.component";
import BigCardComingSoon from "../../components/big-card/big-card-coming-soon.component";
import { closeMenu } from "../../store/features/menuDrawer/menuSlice";
import { useDispatch } from "react-redux";
import { format } from "date-fns-tz";
import { isAfter } from "date-fns";
import { API_BASE_URL } from '../../config/api.config';

const MyExperiments = () => {
  const dispatch = useDispatch();
  const loginToken = localStorage.getItem("access_token");
  const userInfo = getUserInfo();
  const now = new Date();
  const centralEuropeanTimeZone = "Europe/Berlin";
  const currentDate = format(now, "yyyy,MM,dd", {
    timeZone: centralEuropeanTimeZone,
  });

  const [metricsData, setMetricsData] = useState({});

  const [runningExperiments, setRunningExperiments] = useState([]);
  // eslint-disable-next-line
  const [pendingExperiments, setPendingExperiments] = useState([]);
  // eslint-disable-next-line
  const [incomingExperiments, setIncomingExperiments] = useState([]);
  const [subtitle, setSubtitle] = useState("");

  const [filters, setFilters] = useState({
    groups: "default", //label: "Grupo"
    clusters: "default", //label: "Posición"
    locations: "default", //label: "Ubicación"
    categories: "default", //label: "Tipo"
    machines: "default", //label: "Máquinas"
  });

  const fetchMetrics = async () => {
    if (filters.groups !== "default") {
      try {
        if (userInfo) {
          const { data } = await axios.get(
            `${API_BASE_URL}/experiments?clientId=${encodeURIComponent(userInfo.customer_id)}&group=${encodeURIComponent(filters.groups)}&period=w&storeClusterId=${encodeURIComponent(filters.clusters)}&location=${encodeURIComponent(filters.locations)}&category=${encodeURIComponent(filters.categories)}&storeId=${encodeURIComponent(filters.machines)}`
          );
          setMetricsData(data);
          setPendingExperiments(
            data?.experiments?.filter(
              (element) =>
                element?.status?.status === "pending" &&
                element.incoming === false
            )
          );
          setRunningExperiments(
            data?.experiments?.filter(
              (element) =>
                element?.status?.status === "running" &&
                element.incoming === false
            )
          );
          setIncomingExperiments(
            data?.experiments?.filter((element) => element?.incoming)
          );
        }
      } catch (error) {
        console.error("Error fetching metrics:", error);
      }
    }
  };

  useEffect(
    () => {
      // Verify if access token isn't null
      if (loginToken !== null) {
        // Verify if axios headers are setted.
        if (axios.defaults.headers.common["Authorization"]) {
          fetchMetrics();
        } else {
          // If axios headers aren't setted, wait untill they are.
          const interval = setInterval(() => {
            if (axios.defaults.headers.common["Authorization"]) {
              clearInterval(interval);
              fetchMetrics();
            }
          }, 100); // Verification interval.
        }
      }
    },
    // eslint-disable-next-line
    [filters, loginToken]
  );

  const bigCardFirstSlider = {
    title: "Ingresos Incrementales",
    headline: "+€ Experimentos",
  };
  const bigCardSecondSlider = {
    title: "Unidades Incrementales",
    headline: "+U Experimentos",
  };
  const bigCardThirdSlider = {
    title: "Beneficios Incrementales",
  };

  return (
    <>
      <Header
        title={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ fontWeight: "normal" }}>Mis</div>
            <div style={{ fontWeight: "bold" }}>&nbsp;Experimentos</div>
          </div>
        }
        avatar="FB"
      />
      <Box>
        <Grid
          sx={{
            height: "100%",
            paddingBlockStart: "7rem",
            paddingInlineStart: "50px",
          }}
          container
        >
          <Filters
            disableFirstMachineSelected
            setBigCardSubtitle={setSubtitle}
            filters={filters}
            setFilters={setFilters}
          />
          <DrawerMenu activeOptionId={2} activeSubsectionId={0} />
          {/* <Grid
            container
            sx={{
              paddingBlockStart: "2.5rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: theme.palette.purple.main,
                color: "#fff",
                padding: "2px 10px",
                borderRadius: "5px",
                marginBlockStart: "0.5rem",
              }}
            >
              <Typography>
                <FormatPeriod
                  period={period}
                  startDate={metricsData?.period?.current?.startDate}
                  endDate={metricsData?.period?.current?.endDate}
                />
              </Typography>
            </Grid>
          </Grid> */}
          <Grid
            onClick={() => dispatch(closeMenu())}
            container
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              paddingBlockStart: "76px",
            }}
          >
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <BigCard
                experimentsPage
                bigCardData={{
                  ...bigCardFirstSlider,
                  subtitle: subtitle,
                }}
                metricsData={metricsData}
                logo="experiment"
                slideIndex="0"
              />
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {" "}
              <BigCard
                experimentsPage
                bigCardData={{ ...bigCardSecondSlider, subtitle: subtitle }}
                metricsData={metricsData}
                logo="experiment"
                slideIndex="1"
              />
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {" "}
              <BigCardComingSoon
                experimentsPage
                bigCardData={{ ...bigCardThirdSlider, subtitle: subtitle }}
                metricsData={metricsData}
              />
            </Grid>
            <Grid container sx={{ marginBlockStart: "1rem" }}>
              {/* RUNNING EXPERIMENTS SMALL CARD START */}
              <Grid item xs={6}>
                {runningExperiments?.length > 0 && (
                  <ExperimentsSection title="Impacto Experimentos" header="c" />
                )}
                <div
                  style={{
                    marginBlock: "0.5rem",
                  }}
                >
                  {runningExperiments?.map((element) => {
                    const periodEnd = new Date(element?.period?.end);
                    if (isAfter(periodEnd, new Date(currentDate))) {
                      return (
                        <RunningExperimentsCard
                          key={element?.id}
                          {...element}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </Grid>
              {/* RUNNING EXPERIMENTS SMALL CARD END */}

              {/* RUNNING EXPERIMENTS START */}

              <Grid item xs={6}>
                {runningExperiments?.length > 0 && (
                  <Section title="Problemas / Acciones" />
                )}
                <div
                  style={{
                    marginBlock: "0.5rem",
                  }}
                >
                  {runningExperiments?.map((element) => {
                    const periodEnd = new Date(element?.period?.end);
                    if (isAfter(periodEnd, new Date(currentDate))) {
                      return (
                        <PendingExperimentsCard
                          fetchMetrics={fetchMetrics}
                          {...element}
                          key={element?.id}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </Grid>
              {/* RUNNING EXPERIMENTS END */}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default MyExperiments;
