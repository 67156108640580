import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { ReactComponent as MymaLogo } from "../../assets/images/myma-icon.svg";
import { CustomInput } from "../../components/custom-input/custom-input.component";
import { setAuthToken } from "../../services/utils";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { API_BASE_URL } from '../../config/api.config';

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      navigate("/home");
    } else {
      const storedUsername = localStorage.getItem("username");
      const storedRememberMe = localStorage.getItem("rememberMe");
      if (storedUsername && storedRememberMe === "true") {
        setUsername(storedUsername);
        setRememberMe(true);
      } else {
        setUsername("");
      }
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (!username || !password) {
      setError("Por favor ingrese usuario y contraseña.");
      return;
    }

    try {
      setIsLoading(true);
      let response;
      response = await axios.post(`${API_BASE_URL}/auth/login`, {
        username,
        password,
      });
      const { access_token } = response.data;
      setAuthToken(access_token);
      localStorage.setItem("access_token", access_token);

      response = await axios.get(`${API_BASE_URL}/auth/userinfo`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

      localStorage.setItem("userInfo", JSON.stringify(response.data));

      if (rememberMe) {
        localStorage.setItem("username", username);
        localStorage.setItem("rememberMe", "true");
      } else {
        localStorage.removeItem("username");
        localStorage.removeItem("rememberMe");
      }
      navigate("/");
    } catch (error) {
      setError("Credenciales inválidas.");
      setIsLoading(false);
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          backgroundColor: "#663b73",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          borderTopRightRadius: "150px",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{ display: "flex", flexDirection: "column", height: "520px" }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                maxHeight: "3rem",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <MymaLogo fill="#fff" />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                maxHeight: "4rem",
                alignItems: "start",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "2rem",
                  lineHeight: "2rem",
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                  color: "#fff",
                }}
              >
                Ingresa a tu cuenta
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
              }}
            >
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "30%",
                }}
              >
                <CustomInput
                  disabled={isLoading}
                  placeholder="Correo Electrónico"
                  value={username}
                  type="text"
                  onChange={(e) => setUsername(e.target.value)}
                />
                <CustomInput
                  endAdornment={
                    <InputAdornment
                      sx={{
                        ".MuiIconButton-root:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      position="start"
                    >
                      {isLoading ? null : (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                          sx={{
                            height: "1.91rem",
                            borderRadius: "0",
                            padding: 0,
                          }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      )}
                    </InputAdornment>
                  }
                  placeholder="Contraseña"
                  disabled={isLoading}
                  value={password}
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Checkbox
                    disabled={isLoading}
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    color="white"
                    sx={{ color: "rgb(192, 154, 203)" }}
                  />
                  <Typography sx={{ color: "#fff" }}>
                    Recordar mi correo
                  </Typography>
                </Grid>

                <Button
                  variant="contained"
                  label="Ingresar"
                  color="white"
                  type="submit"
                  disabled={isLoading}
                  sx={{
                    fontSize: 18,
                    fontWeight: 900,
                    margin: "8px 0",
                    backgroundColor: "#fff",
                    color: "#663b73",
                    boxShadow: "none",
                    textTransform: "none",
                    padding: "6px 12px",
                    border: "1px solid",
                    borderColor: "#fff",
                    borderRadius: "2rem",
                    width: "100%",
                    height: "64px",
                    // span: {
                    //   margin: "0 10px",
                    // },
                    "&:disabled": {
                      backgroundColor: "#fff",
                    },
                  }}
                >
                  {isLoading ? (
                    <CircularProgress size="2rem" sx={{ color: "#663b73" }} />
                  ) : (
                    "Ingresar"
                  )}
                </Button>

                {error && (
                  <div style={{ color: "#fff", marginBlockStart: "1rem" }}>
                    {error}
                  </div>
                )}
              </form>
              {/* </div> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
