import React from "react";
import { useEffect, useState } from "react";
import Header from "../../components/header/header.component";
import Section from "../../components/section/section.component";
import axios from "axios";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  useTheme,
} from "@mui/material";
import Filters from "../../components/filters/filters.component";
import { getUserInfo } from "../../services/userInfo.service";
import DrawerMenu from "../../components/drawer/drawer.component";
import { API_BASE_URL } from '../../config/api.config';

import EmptyAlerts from "./emptyalerts.component";
import AlertSmallCard from "../../components/alerts-small-card/alerts-small-card.component";
import { useDispatch } from "react-redux";
import { closeMenu } from "../../store/features/menuDrawer/menuSlice";

const alertsSourceEquivalency = {
  costos: "Notificaciones por Costos",
  eventos: "Notificaciones por Eventos",
  clima: "Notificaciones por Clima",
  competencia: "Notificaciones por Competencia",
  mercado: "Notificaciones por Mercado",
  rotacion: "Notificaciones por Rotación",
};

const MyAlerts = () => {
  const dispatch = useDispatch();
  const loginToken = localStorage.getItem("access_token");
  const userInfo = getUserInfo();
  const theme = useTheme();
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [alertIdToDelete, setAlertIdToDelete] = useState(null);
  const [alerts, setAlerts] = useState([]);

  const [filters, setFilters] = useState({
    groups: "default", //label: "Grupo"
    clusters: "default", //label: "Posición"
    locations: "default", //label: "Ubicación"
    categories: "default", //label: "Tipo"
    machines: "default", //label: "Máquinas"
  });

  const fetchAlerts = async () => {
    try {
      if (userInfo) {
        const { data } = await axios.get(
          `${API_BASE_URL}/notifications?clientId=${encodeURIComponent(userInfo.customer_id)}&group=${encodeURIComponent(filters.groups)}&period=w&storeClusterId=${encodeURIComponent(filters.clusters)}&location=${encodeURIComponent(filters.locations)}&category=${encodeURIComponent(filters.categories)}&storeId=${encodeURIComponent(filters.machines)}`
        );

        // Filter and group notifications by source property, excluding archived alerts.
        const groupedAlerts = data.reduce((grouped, alert) => {
          const { source, archived } = alert;
          if (!archived) {
            if (!grouped[source]) {
              grouped[source] = [];
            }
            grouped[source].push(alert);
          }
          return grouped;
        }, {});

        setAlerts(groupedAlerts);
      }
    } catch (error) {
      console.error("Error fetching alerts:", error);
    }
  };

  useEffect(
    () => {
      if (loginToken) {
        if (axios.defaults.headers.common["Authorization"]) {
          fetchAlerts();
        } else {
          const interval = setInterval(() => {
            if (axios.defaults.headers.common["Authorization"]) {
              clearInterval(interval);
              fetchAlerts();
            }
          }, 100);
        }
      }
    },
    // eslint-disable-next-line
    [filters, loginToken]
  );

  const handleDeleteAlert = (alertId) => {
    const apiUrl = `${API_BASE_URL}/notifications/${alertId}/archive?clientId=${encodeURIComponent(userInfo.customer_id)}`;

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (userInfo) {
      axios
        .post(apiUrl, {}, config)
        .then((response) => {
          // console.log("Alert archived successfully:", response.data);
          setConfirmDeleteDialogOpen(false);
          fetchAlerts();
        })
        .catch((error) => {
          // console.error("Error archieving alert:", error);
        });
    }
  };

  return (
    <>
      <Header
        title={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ fontWeight: "normal" }}>Mis</div>
            <div style={{ fontWeight: "bold" }}>&nbsp;Notificaciones</div>
          </div>
        }
        avatar="FB"
      />
      <Box>
        <Grid
          sx={{
            height: "100%",
            paddingBlockStart: "7rem",
            paddingInlineStart: "50px",
          }}
          container
        >
          <Dialog
            open={confirmDeleteDialogOpen}
            onClose={() => setConfirmDeleteDialogOpen(false)}
          >
            <DialogTitle>Confirmar eliminación</DialogTitle>
            <DialogContent>
              <DialogContentText>
                ¿Estás seguro de que deseas eliminar ésta notificación?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setConfirmDeleteDialogOpen(false)}
                style={{ color: theme.palette.divider.main }}
              >
                Cancelar
              </Button>
              <Button
                onClick={async () => {
                  handleDeleteAlert(alertIdToDelete);
                }}
                style={{ color: theme.palette.divider.main }}
              >
                Eliminar
              </Button>
            </DialogActions>
          </Dialog>
          <Filters
            disableFirstMachineSelected
            filters={filters}
            setFilters={setFilters}
          />
          <DrawerMenu activeOptionId={3} />
          <Grid
            onClick={() => dispatch(closeMenu())}
            container
            sx={{
              width: "100%",
              display: "flex",
              paddingBlockStart: "2.5rem",
              justifyContent: "center",
            }}
          >
            {Object.keys(alertsSourceEquivalency).map((source) => (
              <Grid
                item
                xs={4}
                key={source}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "1rem",
                  paddingBlockEnd: "1rem",
                }}
              >
                <Section title={alertsSourceEquivalency[source]} />
                {alerts[source] && alerts[source].length > 0 ? (
                  alerts[source]
                    .slice()
                    .reverse()
                    .map((alert) => {
                      if (!alert.archived) {
                        return (
                          <AlertSmallCard
                            key={alert.id}
                            setConfirmDeleteDialogOpen={
                              setConfirmDeleteDialogOpen
                            }
                            setAlertIdToDelete={setAlertIdToDelete}
                            {...alert}
                          />
                        );
                      } else {
                        return null;
                      }
                    })
                ) : (
                  <EmptyAlerts source={source} />
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default MyAlerts;
