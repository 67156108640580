import React from "react";
import { Box, Grid, Paper, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { closeMenu } from "../../store/features/menuDrawer/menuSlice";
import Header from "../../components/header/header.component";
import DrawerMenu from "../../components/drawer/drawer.component";

const Planogram = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const customerName = JSON.parse(
    localStorage.getItem("userInfo")
  )?.customer_name;

  const getIframeUrl = () => {
    switch(customerName) {
      case 'Natica':
        return "https://app.powerbi.com/view?r=eyJrIjoiYTI1MWVjNjQtMTBmZS00NjlkLTk1ZDQtMjVkNTRjMzllN2RhIiwidCI6Ijg0MjIzYmRmLWY5OGMtNDdiYy1iNGQyLTBhZmIzZDNiYzNlYiJ9";
      default:
        return "";
    }
  };

  return (
    <>
      <Header
        title={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ fontWeight: "bold" }}>Performance Planograma</div>
          </div>
        }
      />
      <Box>
        <Grid
          sx={{
            height: "100%",
            paddingBlockStart: "7rem",
            paddingInlineStart: "50px",
          }}
          container
        >
          <DrawerMenu activeOptionId={9} />
          <Grid
            onClick={() => dispatch(closeMenu())}
            container
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              paddingBlockStart: "40px",
              paddingInline: "1rem",
            }}
          >
            {customerName === 'Natica' ? (
              <Grid
                component={Paper}
                elevation={2}
                item
                xs={12}
                sx={{ 
                  height: "calc(100vh - 200px)",
                  overflow: "hidden",
                }}
              >
                <iframe
                  title="MYMA PLANOGRAM"
                  src={getIframeUrl()}
                  style={{
                    width: "100%",
                    height: "calc(100% - 48px)",
                    border: "none",
                  }}
                  frameBorder="0"
                  allowFullScreen={true}
                  sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation"
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Paper
                  sx={{
                    p: 3,
                    textAlign: 'center',
                    color: theme.palette.text.secondary
                  }}
                >
                  Esta función solo está disponible para Natica
                </Paper>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Planogram; 