import axios from "axios";
import { API_BASE_URL } from '../config/api.config';

export const BASE_URL = API_BASE_URL;

export const createHeader = (access_token) => ({
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
  },
});

export const setAuthToken = (access_token) => {
  if (access_token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};
