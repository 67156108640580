import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/home.component";
import Layout from "./components/layout/layout.component";
import Login from "./pages/login/login.component";
import MyMachines from "./pages/my-machines/my-machines.component";
import MyExperiments from "./pages/my-experiments/my-experiments.component";
import MyInsights from "./pages/my-insights/my-insights.component";
import MyAlerts from "./pages/my-alerts/my-alerts.component";
import MySales from "./pages/my-sales/my-sales.component";
import MyExperimentsManagement from "./pages/my-experiments/management/experiments-management.component";
import MyExperimentsPerformance from "./pages/my-experiments/performance/experiments-performance.component";
import MyReportingBusinessImpact from "./pages/reporting/business-impact/reporting-business-impact.component";
import MyReportingProfitability from "./pages/reporting/profitability/reporting-profitability.component";
import ProductsOverview from "./pages/products-overview/products-overview.component";
import Planogram from "./pages/planogram/planogram.component";

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="home" element={<Home />} />
      <Route path="machines" element={<MyMachines />} />
      <Route path="experiments" element={<MyExperiments />} />
      <Route
        path="experiments/management"
        element={<MyExperimentsManagement />}
      />
      <Route
        path="experiments/performance"
        element={<MyExperimentsPerformance />}
      />
      <Route
        path="reporting/business-impact"
        element={<MyReportingBusinessImpact />}
      />
      <Route
        path="reporting/profitability"
        element={<MyReportingProfitability />}
      />
      <Route path="insights" element={<MyInsights />} />
      <Route path="alerts" element={<MyAlerts />} />
      <Route path="sales" element={<MySales />} />
      <Route path="products-overview" element={<ProductsOverview />} />
      <Route path="planogram" element={<Planogram />} />
      <Route path="login" element={<Login />} />
    </Route>
  </Routes>
);

export default AppRoutes;
